.App {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.titulo {
  font-weight: bold;
}

.bolas {
  margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.bolas .bola {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
  font-size: 14px;
  margin: 3px;
}

.bolas .bola.sorteada {
  background-color: red;
  color: #fff;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.ultimos {
  display: flex;
  margin: 5px;
  background-color: #efefef;
  border: 1px solid #900;
  color: #900;
  padding: 5px 15px;
  border-radius: 10px;
}
.botoes {
  margin-top: 20px;
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.botoes button {
  display: flex;
  margin: 5px;
  background-color: #080;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
}

.botoes button.azul {
  background-color: #008;
}

.bolaSorteada {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #fffa;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: all ease-in 0.5s;
}

.bolaSorteada.sumir {
  opacity: 0;
}

.bolaSorteada div {
  font-size: 80px;
  color: red;
  background-color: yellow;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bolas table {
  th {
    color: #ccc;
  }

  tbody {
    td {
      .bola {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #ccc;
        font-size: 14px;
        margin: 3px;
      }
    }
    tr.even {
      // background-color: rgb(233, 233, 233);
    }
  }
}

.App {
  .infos {
    display: flex;
    justify-content: space-around;
    flex: 1;
    .iteminfo {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.App.Home {
  .titulo {
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 50px;
  }

  .bolas {
    flex: 1;
    table {
      width: 100%;
      
      th {
        color: #ccc;
        font-size: 24px;
      }

      tbody {
        td {
          .bola {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 98%;
            height: 50px;
            border-radius: 50%;
            background-color: #ccc;
            font-size: 24px;
            margin: 3px;
          }
        }
        tr.even {
          // background-color: rgb(233, 233, 233);
        }
      }
    }
  }
}
